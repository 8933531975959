.conatiner {
   height: 100%;
   background-color: black;
   height: 100vh;

}

.loading_nav_tab {
   display: flex;
   align-items: center;
   overflow: auto;
   margin-top: 20px;
   margin-bottom: 12px;

   overflow-y: scroll; /* Allows vertical scrolling */
   scrollbar-width: none; /* For Firefox */
}

.loading_nav_tab::-webkit-scrollbar {
   display: none; /* For Chrome, Safari, and other WebKit browsers */
 }

.loading_nav_tab button {
   padding: 8px 20px;
   background-color: #4e4e4e;
   border-radius: 30px;
   font-size: 15px;
   margin-right: 15px;
   transition: all ease .3s;
   text-transform: capitalize;

}

.wrapper {
   padding: 10px;
   /* position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgb(0, 0, 0);
   overflow-y: auto; */
}

.title_tag {
   font-family: RobotoBold;
   font-size: 35px;
   margin-bottom: 10px;
}

.search_wrapper  {
   position: relative;
   /* margin-bottom: 17px; */
}
.search_wrapper  input {
   width: 100%;
   padding: 10px;
   border-radius: 10px;
   padding-left: 35px;
   background-color: #2a2a2c;
   border: none;
   outline: none;
   font-size: 15px;
   color: white;
}

.search_wrapper  input::placeholder {
   color: #9e9ea4;

}

.search_icon {
   position: absolute;
   top: 10px;
   left: 9px;
}

.clear_icon {
   position: absolute;
   top: 10px;
   right: 9px;

}

.search_icon svg {
   color: #9e9ea4;
}

.task_wrapper {
   height: 75vh;
   overflow-y: auto;
   scrollbar-width: none; /* For Firefox */

}