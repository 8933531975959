
/* Caim reward */
.claim_reward {
   position: fixed;
   bottom: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.458);
   z-index: 1;
 }
 
 .reward_container {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   /* height: 350px; */
   background-color: rgb(35, 35, 35);
   border-top-left-radius: 30px;
   border-top-right-radius: 30px;
   padding: 20px;
   padding-bottom: 1.5rem;
   display: flex;
   /* align-items: center; */
   /* justify-content: flex-end; */
   flex-direction: column;
   /* text-align: center; */
   animation: slide_up  .3s;
 }
 
 @keyframes slide_up {
   from {
     bottom: -400px;
   }to {
     bottom: 0px;
   }
 }
 
 .claim_reward .main_claim_btn {
   color: white;
   padding: 14px 30px;
   width: 100%;
   /* background-color: #30a8d4; */
   font-size: 20px;
   border-radius: 20px;
   font-weight: RobotoBold;
   font-weight: bolder;
   /* box-shadow: inset -3px -2px 15px #2f2f2f */
   background: rgb(48,168,212);
   background: linear-gradient(1deg, rgba(48, 168, 212, 1) 0%, rgba(34, 77, 93, 1) 0%, rgba(48, 168, 212, 1) 45%);

 }
 
 .reward_container p {
   line-height: 1.7;
   margin: 20px 0px;
 }
 
 .close_reward_modal {
   position: absolute;
   right: 0px;
   top: 0px;
   padding: 20px;
 }
 
 .close_reward_modal svg {
   font-size: 20px;
 }
 
 .close_reward_modal:active {
   opacity: .4;
 }
 

 .bold_text {
   font-family: "RobotoBold";
 }

 .bold_title {
  text-transform: uppercase;
  font-weight: 900;
  font-family: RobotoBold;
}