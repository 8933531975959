.list {
  width: 100%;
  /* background-color: #2b2b2b; */
  padding: 10px 0px;
  /* margin: 10px 0px; */
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  border-radius: 10px;
}

.action_btn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}
.action_btn button {
  background-color: rgba(98, 98, 98, 0.928);
  padding: 10px 20px;
  color: rgb(255, 255, 255);
  border-radius: 30px;
  font-size: 14px;
  /* font-weight: bold; */
}

.action_btn p {
  font-size: 12px;
  margin-bottom: 10px;
  color: #30a8d4;
}

.xx_text {
  font-weight: 900;
  font-size: 17px;
  font-family: RobotoBold;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon_div {
  width: 50px;
  height: 50px;
  background-color: #3d3d3d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-shadow: inset 0px 0px 10px #1111115e;
}

.x_text {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #8d8d93;
  font-weight: 200;
  font-size: 15px;
}
.icon_div i {
  font-size: 20px;
}

.claim_btn {
  background-color: #e5e5e5 !important;
  color: black !important;
}

.row_ {
  display: flex;
  width: 100%;
}

.col1 {
  flex-basis: 16%;
  /* background-color: red; */
}

.col2 {
  flex-basis: 75%;
  /* background-color: pink; */
}
