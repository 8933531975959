.wrapper {
   padding: 10px;
   background-color: black;;
   min-height: 100vh;
}

.amount_section {
   align-items: center;
   justify-content: center;
   flex-direction: column;
   /* padding: 40px; */
   /* line-height: 2; */
   margin-bottom: 2rem;
   background-color: #262626;
   padding: 20px;
   border-radius: 10px;
}

.title_tag {
   font-family: RobotoBold;
   font-size: 35px;
   margin-bottom: 10px;
}


.top_ {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 10px;
   /* border: 1px solid red; */

}

.amount_wrapper {
   display: flex;
   align-items: center;
   margin-bottom: 10px;
   /* justify-content: center; */
}

.amount_wrapper img{
   width: 60px;
   /* background-color: red; */
   margin-left: -15px;
}

.amount_wrapper h3 {
   font-family: RobotoBold;
}



.balance_container {

   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: 20px;
   flex-wrap: wrap;
}

.full_cap_wrapper button {
   padding: 10px 25px;
   border-radius: 40px;
   background-color: rgb(72 72 72);
   /* font-family: RobotoBold; */
   float: right;
   clear: both;
   font-size: 13px;
}

.full_cap_wrapper button:disabled {
   opacity: .7;

}

.x_text {
   text-overflow: ellipsis;
   color: #e2e2e2;
   font-weight: 200;
   font-size: 15px;
 }

 .amount_p {
   font-weight: RobotoBold;
   margin-top: 10px;
   font-weight: 900;
   color: #f3f3f3;
 } 

 .bold_title {
   text-transform: uppercase;
   font-weight: 900;
   font-family: RobotoBold;
 }

 .boost_wrapper {
   padding: 10px;
   /* background-color: #262626; */
   border-radius: 10px;
   margin-top: 10px;
 }