.container {
  width: 100%;
  height: 100vh;
  background-color: black;
  /* padding: 20px; */
}

.task_board {
  width: 100%;
  margin: auto;
  height: 170px;
  background-color: gray;
  /* margin: 30px 0px; */
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background: rgb(122, 122, 122);
  background:linear-gradient(317deg, rgba(122, 122, 122, 1) 0%, rgba(0, 0, 0, 1) 47%, rgba(0, 0, 0, 1) 94%)
   /* linear-gradient(
    90deg,
    rgba(122, 122, 122, 1) 0%,
    rgba(0, 0, 0, 1) 47%,
    rgba(0, 0, 0, 1) 94%
  ); */


  
}

.task_board img {
  width: 100%;
  height: 100%;
}

.task_board h1 {
  position: absolute;
  top: 54%;
  left: 24px;
  width: 87%;
  font-size: 17px;
  font-family: RobotoBold;
  text-align: center;
}

.user_details {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
}

.user_details img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: contain;
}

.date_ {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.date_ p {
  font-size: 10px;
}

.list {
  width: 100%;
  padding: 10px 5px;
  margin: 5 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action_btn button {
  background-color: white;
  padding: 10px 20px;
  color: black;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.xx_text {
  font-weight: bolder;
  font-size: 13px;
  margin-bottom: 5px;
  font-family: "RobotoBold";
}

.coin_value {
  color: #bbb;
  font-size: 15px;
}

.row_ {
  display: flex;
  align-items: center;
}

.icon_div {
  width: 50px;
  height: 50px;
  background-color: #6497d5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-shadow: inset 0px 0px 10px #1111115e;
}

.title {
  font-weight: bolder;
  font-size: 23px;
  padding: 0px 2px;
  margin: 17px 0px;
  font-family: "RobotoBold";
}

.ref_link_container {
  position: absolute;
  top: 25px;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 10px;
}

.ref_link_container button {
  background-color: rgba(78, 78, 78, 0.665);
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ref_link_container button span {
  margin-right: 6px;
  font-size: 15px;
  font-weight: bold;
  font-family: RobotoBold;
}

.ref_link_container button:active {
  opacity: 0.4;
}

.bottom_wrapper {
  padding: 10px 20px;
  height: 75vh;
  overflow-y: auto;
}

.row_between {
   display: flex;
   align-items: baseline;
   justify-content: space-between;
}

.row_between p {
   font-family: 'RobotoBold';
   font-size: 14px;
}

.bold_ {
   font-family: 'RobotoBold';
}