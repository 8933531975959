.nav_wrapper {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.196);
  padding-top: 10px;
  padding-bottom: 1.3rem;
}

.nav_wrapper_2 {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgb(0, 0, 0);
    padding-top: 10px;
    /* padding-bottom: 1rem; */
}

.nav_wrapper a {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-basis: 15%;
  padding: 10px;
  cursor: pointer;
}

.nav_wrapper a p {
   /* display: none; */
  font-size: 11px;
  /* font-weight: lighter; */
  font-family: RobotoBold;
}

.nav_wrapper a svg {
  font-size: 20px;
}

.wrapper_x {
  position: relative;
}

.badge {
  padding: 5px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  right: -6px;
  top: -3px;
}
