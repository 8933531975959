
.list {
   width: 100%;
   /* background-color: #2b2b2b; */
   /* padding: 10px; */
   margin: 17px 0px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-radius: 10px;
   transition: all ease .3s;
   cursor: pointer;
}

.list:active {
   opacity: .5;
}


.action_btn button {
   background-color: white;
   padding: 10px 20px;
   color: black;
   border-radius: 30px;
   font-size: 14px;
   font-weight: bold;
}

.xx_text {
   font-weight: bolder;
   font-size: 15px;
   font-family: RobotoBold;
}

.row_ {
   display: flex;
}

.icon_div  {
   width: 50px;
   height: 50px;
   background-color: black;
   border-radius: 50%;
   display: flex;
   align-items: center;
justify-content: center;
margin-right: 10px;
box-shadow: inset 0px 0px 10px #1111115e;

}

.icon_div i {
   font-size: 13px;
}

.coin_value {
   color: #bbb;
   font-size: 13px;
   margin-top: 4px;
}