  
.container {
   width: 100%;
   position: fixed;
   bottom: 100px;
   left: 0px;
   margin: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 100;
  animation: fade .3s ease;
   padding: 20px;

}


@keyframes fade {
   from {
      opacity: .4;
      margin-bottom: -40px;
   }to {
      opacity: 1;
      margin-bottom: 0px;
   }
}


.inner_wrapper {
   width: 90%;
   background-color: rgb(31, 31, 31);
   padding: 7px 10px 7px 20px;
   border-radius: 20px;
   display: flex;
   align-items: center;
   justify-content: space-between;

}

.inner_wrapper p {
   font-weight: bolder;
   /* font-family: RobotoBold; */
   font-size: 14px;
   text-align: center;
}

.inner_wrapper button {
   /* background-color: gray; */
   border-radius: 50%;
   width: 30px;
   height: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.inner_wrapper button svg {
   font-size: 15px;
}