.body {
   max-width: 600px;
   /* height: 100; */
   margin: auto;
   background-color: black;
   position: relative;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   /* overflow: hidden; */
 }

 .body_no_bg {
  max-width: 600px;
  /* height: 100vh; */
  margin: auto;
  background-color: unset;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 }


 @media (max-width: 800px) {
  .body {
    max-width: 100%;
    height: 100%;
    /* background-color: black; */
    position: relative;
    
  }
 }