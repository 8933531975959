.container {
   background-color: black;
   padding: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   max-width: 700px;
   margin: auto;
   /* height: 50vh; */
   padding: 20px;
   margin-top: 2rem;
   text-align: center;
}

@media (max-width: 700px) {
   .container {
      width: 100%;
      height: 100vh;
      border-radius: 20px;
      margin-top: 0rem;

   }
}

.scanner  {
   margin-top: 2rem;
}
.scanner img {
   width: 250px;
   height: auto;
   border-radius: 20px;
}

.container p {
   width: 400px;
   text-align: center;
margin-top: 10px;   
}

.scanner h3 {
   font-family: RobotoBold;
   text-align: center;
   margin: 10px;
}