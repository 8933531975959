* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* transition: all ease .2s; */
  /* border: 1px solid red; */
}

html {
  scroll-behavior: smooth;
  /* touch-action: pan-x pan-y;
  height: 100%  */
}

body,
html {
  height: 100%;
  margin: 0;
  overflow: hidden; /* Prevents scrollbars */
  scrollbar-width: none; /* For Firefox */
}

@font-face {
  font-family: "Roboto";
  src: url("../public/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoBold";
  src: url("../public/Roboto/Roboto-Bold.ttf") format("truetype");
}

body {
  touch-action: pan-x pan-y;
  height: 100%;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  /* background-color: #000000 !important; */
  /* transition: all ease .5s; */
  scroll-behavior: smooth;
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
  user-select: none; /* Disable text selection */
  -webkit-user-select: none; /* For Chrome, Safari, and other WebKit browsers */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
  background-color: black;

  background-image: radial-gradient(circle at center 43%, rgb(0, 0, 0) -35%, rgba(21, 95, 122, 0.96) 3%, rgb(0, 0, 0) 48%);

  /* background-image: radial-gradient(
    circle at center 47%,
    rgb(0, 0, 0) -35%,
    rgba(26, 119, 153, 0.96) 3%,
    rgb(0, 0, 0) 48%
  ); */
  /* background-image: radial-gradient(
    circle at center 47%,
    rgb(0, 0, 0) -35%,
    rgb(30, 64, 76) 3%,
    rgb(0, 0, 0) 140%
  ); */
}



@media screen and (min-width: 1300px) {
  body {
    max-width: 1300px;
    margin: 0 auto;
  }
}

.table_wrapper {
  width: 100%;
  max-width: 900px;
  overflow: auto;
}

tbody tr:nth-child(odd) {
  background-color: rgb(55, 55, 55);
  color: white;
  border: none;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: unset;
}

i {
  cursor: pointer;
  color: #dadada;
}

svg {
  cursor: pointer;
}

img {
  object-fit: cover;
  cursor: pointer;
}

button {
  cursor: pointer !important;
  outline: none;
  border: none;
  background-color: unset;
  color: white;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  border-radius: 20px;
  background-color: rgb(135, 135, 135);
}

/* Track */
::-webkit-scrollbar-track {
  background: unset;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(227, 227, 227);
  border-radius: 20px;
}

@media screen and (max-width: 600px) {
  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(99, 99, 99, 0.4);
}

/* Prevent the app from zooming on mobile devices */
:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

/* preventing IOS mobile from zooming */
@media screen and (max-width: 767px) {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  input {
    font-size: 16px !important;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
  }
}

textarea {
  font-size: 16px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  border: none;
  outline: none;
  resize: none;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color: rgb(0, 0, 0);
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.rdw-dropdown-selectedtext span,
.rdw-dropdownoption-default {
  color: black !important;
}

.send_email_btn {
  padding: 10px 20px;
  background-color: #ca4bd3;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  margin-top: 20px;
}

.section {
  padding: 30px;
}

.short_tr {
  width: 70px;
  word-wrap: break-word;
}

@media (max-width: 600px) {
  .short_tr {
    width: 100%;
  }
}

.skeleton {
  background: linear-gradient(90deg, #767676 25%, #616161 50%, #6f6f6f 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.skeleton_row {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.line_skeleton {
  border-radius: 10px;
  margin-left: 10px;
}
.suspece_styles {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
}

button[data-tc-connect-button="true"] svg {
  background-color: unset !important;
  width: 50px;
  height: 50px;
}

#ton-connect-button button svg {
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}


/* For WebKit browsers */
::-webkit-scrollbar {
  display: none;
  width: 0px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #000000; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0); /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 0, 0); /* Color on hover */
}