.container {
  padding-top: 20px;
  padding: 20px;
  background-color: black;
  height: 100vh;
  /* background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 84%, rgba(122,122,122,1) 94%); */
}
.wrapper_with_vh {
  height: 90vh;
  overflow-y: scroll;


  scrollbar-width: none; /* For Firefox */
}

.list {
  width: 100%;
  padding: 5px 0px;
  margin-bottom: 10px;
  /* margin: 5px 0px; */
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.action_btn button {
  background-color: white;
  padding: 10px 20px;
  color: black;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
}

.xx_text {
  font-weight: bolder;
  margin-bottom: 5px;
  font-family: "RobotoBold";
  font-size: 15px;
}

.coin_value {
  color: #bbb;
  font-size: 15px;
}

.row_ {
  display: flex;
  align-items: center;
}

.icon_div {
  width: 40px;
  height: 40px;
  background-color: #6497d5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-shadow: inset 0px 0px 10px #1111115e;
}

.icon_div i {
  font-size: 20px;
}

.row_title {
  display: flex;
  align-items: center;
}

.top_leader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* padding: 30px 0px; */
  /* height: 200px; */
  margin: 20px 0px;
  margin-bottom: 40px;
}

.top_leader img {
  /* width: 80px;
  height: 80px; */
  border-radius: 50%;
  padding: 10px;
  border: 2px solid rgb(47, 47, 47);
}

.top_list {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100px;
}



.top_list p {
  margin-top: 10px;
  font-weight: bolder;
  font-size: 12px;
}

.top_list h5 {
  font-weight: bolder;
  color: #ddd;
}
.sub_wrapper {
  /* background-color: white; */
  /* color: black; */
  /* border-top-left-radius: 30px;
   border-top-right-radius: 30px;
   padding-top: 10px;
   margin-top: 10px;
   height: 70vh;
   overflow: auto; */
}

.title {
  font-weight: bolder;
  font-size: 35px;
    margin-bottom: 15px;
  font-weight: bolder;
  font-family: "RobotoBold";
}
