.dot {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
  border-left-color: rgba(0, 0, 0, 0);
  animation: spin 1s linear forwards infinite;
  display: block;
  margin: auto;
}

.small {
  width: 20px;
  height: 20px;
  border: 2px solid rgb(255, 255, 255);
  border-left-color: rgba(0, 0, 0, 0);
  animation: spin 0.5s linear forwards infinite;
  animation: spin 0.5s linear forwards infinite;
  display: block;
  border-radius: 50%;
  margin: auto;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
