.container {
  padding: 10px;
  height: 100%;
  background-color: black;
}
.container h1 {
  font-family: RobotoBold;
  font-size: 35px;
  margin-bottom: 10px;
}



.input_wrapper {
  margin: 10px 0px;
}

.video_watch {
  font-weight: bolder;
  color: #30a8d4;
  text-decoration: underline;
  font-size: 13px;
  text-transform: uppercase;
  font-style: italic;
}
.input_wrapper input {
  padding: 10px;
  width: 100%;
  border: none;
  outline: none;
  background-color: rgb(35, 35, 35);
  margin-bottom: 10px;
  color: white;
}

.input_wrapper button {
  padding: 10px 30px;
  margin: 6px 0px;
  background-color: #30a8d4;
  color: white;
  font-size: 15px;
  border-radius: 40px;
}

.container hr {
  background-color: rgb(64, 64, 64);
  height: 3px;
  border: none;
}
