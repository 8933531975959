.container {
    background: url("../../public//images/bg.webp") no-repeat;
    /* background-size: cover; */
    height: 64vh;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    text-align: center;
}

@media (max-width: 700px) {
    .container {
        height: 82vh;
    }
}

.container h1 {
    font-size: 30vh;
}

.container h3 {
    line-height: 1.6;
}

@media (max-width: 700px) {
    .container h1 {
       font-size: 5rem
    }
}

.container  button {
    background: rgb(0, 0, 0);
    padding: 10px 20px;
    border-radius: 20px;
    color: white;
    margin-top: 2rem;
}